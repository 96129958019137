import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiListBoxOutline,  mdiMinus, mdiPlus } from "@mdi/js"; 
import { useState, useEffect, useRef } from "react";
import React from "react-moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const EditApplication = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    package_name: '',
    os: '',
    description: '',
    game_ad_provider: '',
    prompt_rate_app_days: '',
    prompt_rate_app_launch: '',
    click_count_for_interstitial_ad: '',
    disable_banner_ad: '',
    disable_interstitial_ad: '',
    status: '',
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [showAdditionalSection, setShowAdditionalSection] = useState(true);
  const [sections, setSections] = useState([]);
  const [addSectionData, setAddNewSection] = useState([]);
  const isFirstClickRef = useRef(true);
  const [app_open_ad_provider, setAppOpenAdProvider] = useState('');
  const [banner_ad_provider, setBannerAdProvider] = useState('');
  const [interstitial_ad_provider, setInterstitialAdProvider] = useState('');
  const [facebook_ad_provider, setFacebookAdProvider] = useState('');
  const [newSection, setNewSection] = useState([]);
  const [showAddSectionDropdown, setShowAddSectionDropdown] = useState(false);
  
  useEffect(() => {
    //fetch(`http://localhost:8000/getApplicationDataById/${id}`)
    fetch(`https://adkeys.loopbots.in/getApplicationDataById/${id}`)
      .then((response) => response.json())
      .then((data) => {
        const { app_open_ad_provider, banner_ad_provider, interstitial_ad_provider, facebook_ad_provider} = data.data;
        setFormData(data.data);
        const newSections = [];
        
        if (data.admobData && data.admobData.length > 0) {
          data.admobData.forEach((admobDataItem, index) => {
            const { admob_app_id, app_open_key, banner_key, interstitial_key, reward_key, native_key, rectangle_key, status, description, app_open_key_checkbox, banner_key_checkbox, interstitial_key_checkbox, reward_key_checkbox, native_key_checkbox, rectangle_key_checkbox } = admobDataItem;
            newSections.push({
              id: index + 1,
              //showRemoveButton: index === data.admobData.length - 1,
              showRemoveButton: true,
              app_open_ad_provider: app_open_ad_provider || '',
              admob_app_id: admob_app_id || '',
              app_open_key: app_open_key || '',
              banner_key: banner_key || '',
              interstitial_key: interstitial_key || '',
              reward_key: reward_key || '',
              native_key: native_key || '',
              rectangle_key: rectangle_key || '', 
              status: status || '',
              description: description || '',
              app_open_key_checkbox: app_open_key_checkbox,
              banner_key_checkbox: banner_key_checkbox,
              interstitial_key_checkbox: interstitial_key_checkbox,
              reward_key_checkbox: reward_key_checkbox,
              native_key_checkbox: native_key_checkbox,
              rectangle_key_checkbox: rectangle_key_checkbox,
            });
          });
          setAppOpenAdProvider(true);
        } 
        if (data.appLovinData && data.appLovinData.length > 0) {
            data.appLovinData.forEach((appLovinDataItem, index) => {
            const { sdk_key, report_key, event_key, ad_review_key, app_open_key, banner_key, interstitial_key, rectangle_key, status, description, app_open_key_checkbox, banner_key_checkbox, interstitial_key_checkbox, rectangle_key_checkbox } = appLovinDataItem;
            newSections.push({
              id: newSections.length + 1,
              //showRemoveButton: index === data.appLovinData.length - 1,
              showRemoveButton: true,
              //banner_ad_provider: 'app_lovin',
              banner_ad_provider: banner_ad_provider || '',
              sdk_key: sdk_key || '',
              report_key: report_key || '',
              event_key: event_key || '',
              ad_review_key: ad_review_key || '',
              app_open_key: app_open_key || '',
              banner_key: banner_key || '',
              interstitial_key: interstitial_key || '',
              rectangle_key: rectangle_key || '',
              status: status || '',
              description: description || '',

              app_open_key_checkbox: app_open_key_checkbox,
              banner_key_checkbox: banner_key_checkbox,
              interstitial_key_checkbox: interstitial_key_checkbox,
              rectangle_key_checkbox: rectangle_key_checkbox,
            });
          });
          setBannerAdProvider(true);
        }
        if (data.adSterraData && data.adSterraData.length > 0) {
          data.adSterraData.forEach((adSterraDataItem, index) => {
            const {ad_url, rectangle_key, status, description, ad_url_checkbox } = adSterraDataItem;
            newSections.push({
              id: newSections.length + 1,
              //interstitial_ad_provider: 'adsterra',
              interstitial_ad_provider: interstitial_ad_provider || '',
              //showRemoveButton: index === data.adSterraData.length - 1,
              showRemoveButton: true,
              ad_url: ad_url || '',
              rectangle_key: rectangle_key || '',
              status: status || '',
              description: description || '',
              ad_url_checkbox: ad_url_checkbox,
            });
          });
          setInterstitialAdProvider(true);
        } 
        if (data.facebookData && data.facebookData.length > 0) {
          data.facebookData.forEach((facebookDataItem, index) => {
            const {fb_app_id,app_open_key,banner_key,interstitial_key,rectangle_key, status, description, app_open_key_checkbox, banner_key_checkbox, interstitial_key_checkbox, rectangle_key_checkbox } = facebookDataItem;
            newSections.push({
              id: newSections.length + 1,
              //facebook_ad_provider: 'facebook_data',
              facebook_ad_provider: facebook_ad_provider || '',
              //showRemoveButton: index === data.facebookData.length - 1,
              showRemoveButton: true,
              fb_app_id: fb_app_id || '',
              app_open_key: app_open_key || '',
              banner_key: banner_key || '',
              interstitial_key: interstitial_key || '',
              rectangle_key: rectangle_key || '',
              status: status || '',
              description: description || '',

              app_open_key_checkbox: app_open_key_checkbox,
              banner_key_checkbox: banner_key_checkbox,
              interstitial_key_checkbox: interstitial_key_checkbox,
              rectangle_key_checkbox: rectangle_key_checkbox,
            });
          });
          setFacebookAdProvider(true);
        }
        
        setSections(newSections);
        setLoading(false);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [id]);

  
  const [isSectionAdded, setIsSectionAdded] = useState(false);
  const addSection = (event) => {
    event.preventDefault();
    let newSectionData = {
      id: sections.length + addSectionData.length + 1,
      showRemoveButton: true,
    };
    setAddNewSection([...addSectionData, newSectionData]);
    setShowAddSectionDropdown(true);
    setIsSectionAdded(true);
  };

  const removeSection = (id) => {
    const updatedSections = sections.filter((section) => section.id !== id);
    setSections(updatedSections);
  };

  const removeSectionNew = (id) => {
    const updatedSectionsNew = addSectionData.filter((sectionNew) => sectionNew.id !== id);
    setAddNewSection(updatedSectionsNew);
  };

  const handleSectionInputChangeNew = (e, sectionIdNew, fieldName) => {
    const updatedSectionsNew = addSectionData.map((sectionNew) => {
      if (sectionNew.id === sectionIdNew) {
        let fieldValue = e.target.value;
        if (fieldName !== 'description') {
          fieldValue = fieldValue.trim();
        }
        return {
          ...sectionNew,
          [fieldName]: fieldValue,
          status: 'active',
        };
      } else if (sectionNew.status === 'active') {
        return {
          ...sectionNew,
          status: 'inactive',
        };
      }
      return sectionNew;
    });
    setAddNewSection(updatedSectionsNew);
  };

  const handleCheckboxChangeNew = (sectionId, fieldName) => {
    setAddNewSection((prevNewSections) => {
      const updatedNewSections = prevNewSections.map((sectionNew) => {
        if (sectionNew.id === sectionId) {
          return {
            ...sectionNew,
            [fieldName]: !sectionNew[fieldName],
          };
        }
        return sectionNew;
      });
      return updatedNewSections;
    });
    
    setAddNewSection((prevNewSections) => {
      const updatedNewSections = prevNewSections.map((sectionNew) => {
        if (sectionNew.id !== sectionId) {
          return {
            ...sectionNew,
            [fieldName]: false,
          };
        }
        return sectionNew;
      });
      return updatedNewSections;
    });

    setSections((prevSections) => {
      const updatedSections = prevSections.map((section) => {
        if (section[fieldName] && section.id !== sectionId) {
          return {
            ...section,
            [fieldName]: false,
          };
        }
        return section;
      });
      return updatedSections;
    });
  };

  const handleCheckboxChange = (sectionIndex, checkboxName) => {
    setAddNewSection((prevNewSections) => {
      const updatedNewSections = prevNewSections.map((sectionNew) => {
        if (sectionNew.id !== sectionIndex) {
          return {
            ...sectionNew,
            [checkboxName]: false,
          };
        }
        return sectionNew;
      });
      return updatedNewSections;
    });
    setSections((prevSections) => {
      const updatedSections = prevSections.map((section, index) => {
        if (index === sectionIndex) {
          const updatedSection = {
            ...section,
            [checkboxName]: !section[checkboxName],
          };
          return updatedSection;
        } else {
          const updatedSection = {
            ...section,
            [checkboxName]: false,
          };
          return updatedSection;
        }
      });
      return updatedSections;
    });
  };

  const handleNewSectionCheckboxChange = (checkboxName) => {
    setSections((prevSections) => {
      const updatedSections = prevSections.map((section) => ({
        ...section,
        [checkboxName]: false,
      }));
      return updatedSections;
    });
    setAddNewSection((prevNewSections) => {
      const updatedNewSections = prevNewSections.map((newSection) => ({
        ...newSection,
        [checkboxName]: !newSection[checkboxName],
      }));
      return updatedNewSections;
    });
  };


  const handleChange = (e, sectionIndex) => {
    const { name, value, type } = e.target; //checked

    if (type === 'checkbox') {
      if (addSectionData.length > 0) {
        handleNewSectionCheckboxChange(name);
      } else {
        handleCheckboxChange(sectionIndex, name);
      }
      //handleCheckboxChange(sectionIndex, name);
    } else if (name.startsWith('status_')) {
      const statusIndex = parseInt(name.split('_')[1]);
      const updatedSections = sections.map((section, index) => {
        if (index === statusIndex) {
          return {
            ...section,
            status: value,
          };
        } else {
          return {
            ...section,
            status: 'inactive',
          };
        }
        //return section;
      });
      setSections(updatedSections);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setSections((prevSections) => {
        const updatedSections = prevSections.map((section, index) => {
          if (index === sectionIndex) {
            return {
              ...section,
              [name]: name === 'description' ? value : value.trim(),
            };
          }
          return section;
        });
        return updatedSections;
      });
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const { name, package_name, os, description, game_ad_provider, prompt_rate_app_days, prompt_rate_app_launch, click_count_for_interstitial_ad, disable_banner_ad, disable_interstitial_ad, status } = formData;

      const updatedSectionsNew = addSectionData.map((section) => {
        let additionalFieldsNew = {};
        if (section.app_open_ad_provider === 'admob') {
          additionalFieldsNew = {
            admob_app_id: section.admob_app_id,
            app_open_key: section.app_open_key,
            banner_key: section.banner_key,
            interstitial_key: section.interstitial_key,
            reward_key: section.reward_key,
            native_key: section.native_key,
            rectangle_key: section.rectangle_key,
            description: section.description,
            status: section.status,
            app_open_key_checkbox: section.app_open_key_checkbox,
            banner_key_checkbox: section.banner_key_checkbox,
            interstitial_key_checkbox: section.interstitial_key_checkbox,
            reward_key_checkbox: section.reward_key_checkbox,
            native_key_checkbox: section.native_key_checkbox,
            rectangle_key_checkbox: section.rectangle_key_checkbox,
          };
        } else if (section.app_open_ad_provider === 'app_lovin') {
          additionalFieldsNew = {
            sdk_key: section.sdk_key,
            report_key: section.report_key,
            event_key: section.event_key,
            ad_review_key: section.ad_review_key,
            app_open_key: section.app_open_key,
            banner_key: section.banner_key,
            rectangle_key: section.rectangle_key,
            description: section.description,
            interstitial_key: section.interstitial_key,
            status: section.status,
            app_open_key_checkbox: section.app_open_key_checkbox,
            banner_key_checkbox: section.banner_key_checkbox,
            interstitial_key_checkbox: section.interstitial_key_checkbox,
            rectangle_key_checkbox: section.rectangle_key_checkbox,
          };
        } else if (section.app_open_ad_provider === 'adsterra') {
          additionalFieldsNew = {
            ad_url: section.ad_url,
            rectangle_key: section.rectangle_key,
            status: section.status,
            description: section.description,
            ad_url_checkbox: section.ad_url_checkbox,
          };
        } else if (section.app_open_ad_provider === 'facebook_data') {
          additionalFieldsNew = {
            fb_app_id: section.fb_app_id,
            app_open_key: section.app_open_key,
            banner_key: section.banner_key,
            interstitial_key: section.interstitial_key,
            rectangle_key: section.rectangle_key,
            status: section.status,
            description: section.description,
            app_open_key_checkbox: section.app_open_key_checkbox,
            banner_key_checkbox: section.banner_key_checkbox,
            interstitial_key_checkbox: section.interstitial_key_checkbox,
            rectangle_key_checkbox: section.rectangle_key_checkbox,
          };
        }
        return {
          ...section,
          ...additionalFieldsNew,
        };
      });
      const updatedSections = sections.map((section) => {
        let additionalFields = {};
        if (section.app_open_ad_provider === 'admob') {
          additionalFields = {
            admob_app_id: section.admob_app_id,
            app_open_key: section.app_open_key,
            banner_key: section.banner_key,
            interstitial_key: section.interstitial_key,
            reward_key: section.reward_key,
            native_key: section.native_key,
            rectangle_key: section.rectangle_key,
            description: section.description,
            status: section.status,
            app_open_key_checkbox: section.app_open_key_checkbox,
            banner_key_checkbox: section.banner_key_checkbox,
            interstitial_key_checkbox: section.interstitial_key_checkbox,
            reward_key_checkbox: section.reward_key_checkbox,
            native_key_checkbox: section.native_key_checkbox,
            rectangle_key_checkbox: section.rectangle_key_checkbox,
          };
        } else if (section.app_open_ad_provider === 'app_lovin') {
          additionalFields = {
            sdk_key: section.sdk_key,
            report_key: section.report_key,
            event_key: section.event_key,
            ad_review_key: section.ad_review_key,
            app_open_key: section.app_open_key,
            banner_key: section.banner_key,
            rectangle_key: section.rectangle_key,
            description: section.description,
            interstitial_key: section.interstitial_key,
            status: section.status,
            app_open_key_checkbox: section.app_open_key_checkbox,
            banner_key_checkbox: section.banner_key_checkbox,
            interstitial_key_checkbox: section.interstitial_key_checkbox,
            rectangle_key_checkbox: section.rectangle_key_checkbox,
          };
        } else if (section.app_open_ad_provider === 'adsterra') {
          additionalFields = {
            ad_url: section.ad_url,
            rectangle_key: section.rectangle_key,
            status: section.status,
            description: section.description,
            ad_url_checkbox: section.ad_url_checkbox,
          };
        } else if (section.app_open_ad_provider === 'facebook_data') {
          additionalFields = {
            fb_app_id: section.fb_app_id,
            app_open_key: section.app_open_key,
            banner_key: section.banner_key,
            interstitial_key: section.interstitial_key,
            rectangle_key: section.rectangle_key,
            status: section.status,
            description: section.description,
            app_open_key_checkbox: section.app_open_key_checkbox,
            banner_key_checkbox: section.banner_key_checkbox,
            interstitial_key_checkbox: section.interstitial_key_checkbox,
            rectangle_key_checkbox: section.rectangle_key_checkbox,
          };
        }
        return {
          ...section,
          ...additionalFields,
        };
      });
      
      const rowData = { 
        name: name,
        package_name: package_name,
        os: os,
        description: description,
        game_ad_provider: game_ad_provider,
        prompt_rate_app_days: prompt_rate_app_days,
        prompt_rate_app_launch: prompt_rate_app_launch,
        click_count_for_interstitial_ad: click_count_for_interstitial_ad,
        disable_banner_ad: disable_banner_ad,
        disable_interstitial_ad: disable_interstitial_ad,
        status: status,
        addSectionData: updatedSectionsNew,
        sections: updatedSections,
      };
      
      updatedSectionsNew.forEach((section) => {
        if (section.app_open_ad_provider === 'admob') {
          rowData.app_open_ad_provider = section.app_open_ad_provider;
        }
        if (section.app_open_ad_provider === 'app_lovin') {
          rowData.banner_ad_provider = section.app_open_ad_provider;
        }
        if (section.app_open_ad_provider === 'adsterra') {
          rowData.interstitial_ad_provider = section.app_open_ad_provider;
        }
        if (section.app_open_ad_provider === 'facebook_data') {
          rowData.facebook_ad_provider = section.app_open_ad_provider;
        }
      });

      updatedSections.forEach((section) => {
        if (section.app_open_ad_provider === 'admob') {
          rowData.app_open_ad_provider = section.app_open_ad_provider;
        }
        if (section.banner_ad_provider === 'app_lovin') {
          rowData.banner_ad_provider = section.banner_ad_provider;
        }
        if (section.interstitial_ad_provider === 'adsterra') {
          rowData.interstitial_ad_provider = section.interstitial_ad_provider;
        }
        if (section.facebook_ad_provider === 'facebook_data') {
          rowData.facebook_ad_provider = section.facebook_ad_provider;
        }
      });

      // updatedSections.forEach((section, index) => {
      //   if (section.app_open_key_checkbox) {
      //     if (section.app_open_ad_provider === 'admob') {
      //       rowData.app_open_ad_provider = section.app_open_ad_provider;
      //     }
      //     if (section.banner_ad_provider === 'app_lovin' && index > 0) {
      //       updatedSections[index - 1].app_open_key_checkbox = false;
      //       rowData.app_open_ad_provider = 'app_lovin';
      //     }
      //   }
      // });

      console.log('Updated Row Data:', rowData);
      //fetch(`http://localhost:8000/editApplicationData/${id}`, {
      fetch(`https://adkeys.loopbots.in/editApplicationData/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rowData),
      })
      .then((response) => response.json())
      .then((data) => {
        toast.success('You have successfully updated application data!');
        navigate("/application");
      })
      .catch((error) => {
        toast.error('Error in update application data!!');
      });
    }
  };

  const { name, package_name, os } = formData;
  const validateForm = () => {
    const errors = {};
    if (!name) {
      errors.name = 'App Name field is required';
    }
    if (!package_name) {
        errors.package_name = 'Package Name field is required';
    }
    if(!os) {
        errors.os = 'OS field is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <Sidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
              style={{ height: "100vh" }}
            >
              <Header />
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div className="toolbar" id="kt_toolbar">
                  <div
                    id="kt_toolbar_container"
                    className="container-fluid d-flex flex-stack"
                  >
                    <div
                      data-kt-swapper="true"
                      data-kt-swapper-mode="prepend"
                      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                      className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                    >
                      <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                        Edit Application
                        <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-xxl" style={{maxWidth: "100%"}}>
                    <div className="g-5 gx-xxl-8">
                      <div className="card card-xxl-stretch">
                        <div className="card-header">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-dark">
                              Edit Application
                            </span>
                          </h3>
                          <div className="card-toolbar">
                            <Link to="/application" className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm"><Icon path={mdiListBoxOutline} size={1} /> Application
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="py-0">
                            <div className="rounded border p-10">
                              <form className="form" onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        App Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="App Name"
                                        name="name"
                                        value={formData.name || ''}
                                        onChange={handleChange}
                                      />
                                      {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Package Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Package Name"
                                        name="package_name"
                                        value={formData.package_name || ''}
                                        onChange={handleChange}
                                      />
                                      {errors.package_name && <span style={{ color: "red" }}>{errors.package_name}</span>}
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        OS
                                      </label>
                                      <select 
                                        className="form-select"
                                        name="os"
                                        value={formData.os}
                                        onChange={handleChange}
                                      >
                                        <option value="">Select OS</option>
                                        <option value="android">Android</option>
                                        <option value="iOS">iOS</option>
                                      </select>
                                      {errors.os && <span style={{ color: "red" }}>{errors.os}</span>}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Description
                                      </label>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Description"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Game Ad Provider (URL)
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Game Ad Provider (URL)"
                                        name="game_ad_provider"
                                        value={formData.game_ad_provider}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Prompt Rate App Days
                                      </label>
                                      <select 
                                        className="form-select"
                                        name="prompt_rate_app_days"
                                        value={formData.prompt_rate_app_days}
                                        onChange={handleChange}
                                      >
                                        <option value="">Select Prompt Rate App Days</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Prompt Rate App Launch
                                      </label>
                                      <select 
                                        className="form-select"
                                        name="prompt_rate_app_launch"
                                        value={formData.prompt_rate_app_launch}
                                        onChange={handleChange}
                                        >
                                        <option value="">Select Prompt Rate App Launch</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Interstitial Ad Click Count
                                      </label>
                                      <select 
                                        className="form-select"
                                        name="click_count_for_interstitial_ad"
                                        value={formData.click_count_for_interstitial_ad}
                                        onChange={handleChange}
                                      >
                                        <option value="">Select Interstitial Ad Click Count</option>
                                        <option value="AA">0</option>
                                        <option value="BB">1</option>
                                        <option value="CC">2</option>
                                        <option value="DD">3</option>
                                        <option value="EE">4</option>
                                        <option value="FF">5</option>
                                        <option value="GG">6</option>
                                        <option value="HH">7</option>
                                        <option value="II">8</option>
                                        <option value="JJ">9</option>
                                        <option value="KK">10</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Banner Ad
                                      </label>
                                      <div className="form-check form-check-custom form-check-solid">
                                        <label className="form-check-label me-2">
                                          Active
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="disable_banner_ad"
                                          value="active"
                                          checked={formData.disable_banner_ad === 'active'}
                                          onChange={handleChange}
                                          />
                                          <label className="form-check-label me-2">
                                          Inactive
                                          </label>
                                          <input
                                          className="form-check-input"
                                          type="radio"
                                          name="disable_banner_ad"
                                          value="inactive"
                                          checked={formData.disable_banner_ad === 'inactive'}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      <label className="form-label">
                                          Interstitial Ad
                                      </label>
                                      <div className="form-check form-check-custom form-check-solid">
                                        <label className="form-check-label me-2">
                                        Active
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="disable_interstitial_ad"
                                          value="active"
                                          checked={formData.disable_interstitial_ad === 'active'}
                                          onChange={handleChange}
                                        />
                                        <label className="form-check-label me-2">
                                        Inactive
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="disable_interstitial_ad"
                                          value="inactive"
                                          checked={formData.disable_interstitial_ad === 'inactive'}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      <label className="form-label">
                                          Status
                                      </label>
                                      <div className="form-check form-check-custom form-check-solid">
                                        <label className="form-check-label me-2">
                                        Active
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="status"
                                          value="active"
                                          checked={formData.status === 'active'}
                                          onChange={handleChange}
                                        />
                                        <label className="form-check-label me-2">
                                        Inactive
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="status"
                                          value="inactive"
                                          checked={formData.status === 'inactive'}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {showAdditionalSection && (
                                  <div className="">
                                      <div className="card card-custom shadow mb-5">
                                        {sections.map((section, index) => (
                                          index !== sections.length + 1 && (
                                          <div key={section.id}>
                                            {section.app_open_ad_provider === 'admob' &&  (
                                              <>
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="card-header" style={{ backgroundColor: '#ccebff' }}>
                                                      <h3 className="card-title align-items-start flex-column">
                                                        <span className="card-label fw-bolder text-dark">
                                                          Section #{section.id}
                                                        </span>
                                                      </h3>
                                                      <div className="card-toolbar">
                                                        {section.showRemoveButton && (
                                                        <button className="btn btn-icon btn-bg-danger btn-active-color-light btn-sm me-3" onClick={() => removeSection(section.id)}><Icon path={mdiMinus} size={1} /></button>
                                                        )}
                                                        {index === sections.length - 1 && (
                                                        <button className="btn btn-icon btn-bg-primary btn-active-color-primary btn-sm" onClick={addSection}>
                                                          <Icon path={mdiPlus} size={1} />
                                                        </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">Ad Provider</label>
                                                      <select
                                                        className="form-select"
                                                        name="app_open_ad_provider"
                                                        value={formData.app_open_ad_provider || ''}
                                                        onChange={handleChange}
                                                      >
                                                        <option value="">Select Ad Provider</option>
                                                        <option value="admob">Admob</option>
                                                        <option value="app_lovin">App Lovin</option>
                                                        <option value="adsterra">Ad Sterra</option>
                                                        <option value="facebook_data">Facebook</option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row" style={{ marginTop: -80 }}>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Admob App Id
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Admob App Id"
                                                        name="admob_app_id"
                                                        value={section.admob_app_id || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        App Open Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`app_open_key_checkbox${section.id}`}
                                                          checked={section.app_open_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'app_open_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="App Open Key"
                                                        name="app_open_key"
                                                        value={section.app_open_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Banner Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`banner_key_checkbox${section.id}`}
                                                          checked={section.banner_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'banner_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Banner Key"
                                                        name="banner_key"
                                                        value={section.banner_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row" style={{ marginTop: -80 }}>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Interstitial Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`interstitial_key_checkbox${section.id}`}
                                                          checked={section.interstitial_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'interstitial_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Interstitial Key"
                                                        name="interstitial_key"
                                                        value={section.interstitial_key}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Reward Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`reward_key_checkbox${section.id}`}
                                                          checked={section.reward_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'reward_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Reward Key"
                                                        name="reward_key"
                                                        value={section.reward_key}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Native Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`native_key_checkbox${section.id}`}
                                                          checked={section.native_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'native_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Native Key"
                                                        name="native_key"
                                                        value={section.native_key}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>                                                
                                                <div className="row" style={{ marginTop: -80 }}>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Rectangle Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`rectangle_key_checkbox${section.id}`}
                                                          checked={section.rectangle_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'rectangle_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Rectangle Key"
                                                        name="rectangle_key"
                                                        value={section.rectangle_key}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                          Status
                                                      </label>
                                                      <div className="form-check form-check-custom form-check-solid">
                                                        <label className="form-check-label me-2">
                                                        Active
                                                        </label>
                                                        <input
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`status_${index}`}
                                                          value="active"
                                                          checked={section.status === 'active'}
                                                          onChange={(e) => handleChange(e, index)}
                                                        />
                                                        <label className="form-check-label me-2">
                                                        Inactive
                                                        </label>
                                                        <input
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`status_${index}`}
                                                          value="inactive"
                                                          checked={section.status === 'inactive'}
                                                          onChange={(e) => handleChange(e, index)}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row" style={{ marginTop: -80 }}>
                                                  <div className="col-lg-12">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Description
                                                      </label>
                                                      <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Description"
                                                        name="description"
                                                        value={section.description}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                            {section.banner_ad_provider === 'app_lovin' && (
                                              <>
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="card-header" style={{ backgroundColor: '#ccebff' }}>
                                                      <h3 className="card-title align-items-start flex-column">
                                                        <span className="card-label fw-bolder text-dark">
                                                          Section #{section.id}
                                                        </span>
                                                      </h3>
                                                      <div className="card-toolbar">
                                                        {section.showRemoveButton && (
                                                        <button className="btn btn-icon btn-bg-danger btn-active-color-light btn-sm me-3" onClick={() => removeSection(section.id)}><Icon path={mdiMinus} size={1} /></button>
                                                        )}
                                                        {index === sections.length - 1 && (
                                                        <button className="btn btn-icon btn-bg-primary btn-active-color-primary btn-sm" onClick={addSection}>
                                                          <Icon path={mdiPlus} size={1} />
                                                        </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">Banner Ad</label>
                                                      <select
                                                        className="form-select"
                                                        name="banner_ad_provider"
                                                        value={formData.banner_ad_provider || ''}
                                                        onChange={handleChange}
                                                      >
                                                        <option value="">Select Ad Provider</option>
                                                        <option value="admob">Admob</option>
                                                        <option value="app_lovin">App Lovin</option>
                                                        <option value="adsterra">Ad Sterra</option>
                                                        <option value="facebook_data">Facebook</option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row" style={{ marginTop: -80 }}>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Sdk Key
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Sdk Key"
                                                        name="sdk_key"
                                                        value={section.sdk_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Report Key
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Report Key"
                                                        name="report_key"
                                                        value={section.report_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Event Key
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Event Key"
                                                        name="event_key"
                                                        value={section.event_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row" style={{ marginTop: -80 }}>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Ad Review Key
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Ad Review Key"
                                                        name="ad_review_key"
                                                        value={section.ad_review_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        App Open Key
                                                        <input
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`app_open_key_checkbox${section.id}`}
                                                          checked={section.app_open_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'app_open_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="App Open Key"
                                                        name="app_open_key"
                                                        value={section.app_open_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Banner Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`banner_key_checkbox${section.id}`}
                                                          checked={section.banner_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'banner_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Banner Key"
                                                        name="banner_key"
                                                        value={section.banner_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row" style={{ marginTop: -80 }}>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Interstitial Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`interstitial_key_checkbox${section.id}`}
                                                          checked={section.interstitial_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'interstitial_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Interstitial Key"
                                                        id="interstitial_key"
                                                        name="interstitial_key"
                                                        value={section.interstitial_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Rectangle Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`rectangle_key_checkbox${section.id}`}
                                                          checked={section.rectangle_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'rectangle_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Rectangle Key"
                                                        name="rectangle_key"
                                                        value={section.rectangle_key}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                          Status
                                                      </label>
                                                      <div className="form-check form-check-custom form-check-solid">
                                                        <label className="form-check-label me-2">
                                                        Active
                                                        </label>
                                                        <input
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`status_${index}`}
                                                          value="active"
                                                          checked={section.status === 'active'}
                                                          onChange={(e) => handleChange(e, index)}
                                                        />
                                                        <label className="form-check-label me-2">
                                                        Inactive
                                                        </label>
                                                        <input
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`status_${index}`}
                                                          value="inactive"
                                                          checked={section.status === 'inactive'}
                                                          onChange={(e) => handleChange(e, index)}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row" style={{ marginTop: -80 }}>
                                                  <div className="col-lg-12">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Description
                                                      </label>
                                                      <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Description"
                                                        name="description"
                                                        value={section.description}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                            {section.interstitial_ad_provider === 'adsterra' && (
                                              <>
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="card-header" style={{ backgroundColor: '#ccebff' }}>
                                                      <h3 className="card-title align-items-start flex-column">
                                                        <span className="card-label fw-bolder text-dark">
                                                          Section #{section.id}
                                                        </span>
                                                      </h3>
                                                      <div className="card-toolbar">
                                                        {section.showRemoveButton && (
                                                        <button className="btn btn-icon btn-bg-danger btn-active-color-light btn-sm me-3" onClick={() => removeSection(section.id)}><Icon path={mdiMinus} size={1} /></button>
                                                        )}
                                                        {index === sections.length - 1 && (
                                                        <button className="btn btn-icon btn-bg-primary btn-active-color-primary btn-sm" onClick={addSection}>
                                                          <Icon path={mdiPlus} size={1} />
                                                        </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">Interstitial Ad</label>
                                                      <select
                                                        className="form-select"
                                                        name="interstitial_ad_provider"
                                                        value={formData.interstitial_ad_provider || ''}
                                                        onChange={handleChange}
                                                      >
                                                        <option value="">Select Ad Provider</option>
                                                        <option value="admob">Admob</option>
                                                        <option value="app_lovin">App Lovin</option>
                                                        <option value="adsterra">Ad Sterra</option>
                                                        <option value="facebook_data">Facebook</option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row" style={{ marginTop: -80 }}>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Ad URL
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`ad_url_checkbox${section.id}`}
                                                          checked={section.ad_url_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'ad_url_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Ad URL"
                                                        name="ad_url"
                                                        value={section.ad_url || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Rectangle Key
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Rectangle Key"
                                                        name="rectangle_key"
                                                        value={section.rectangle_key}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                          Status
                                                      </label>
                                                      <div className="form-check form-check-custom form-check-solid">
                                                        <label className="form-check-label me-2">
                                                        Active
                                                        </label>
                                                        <input
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`status_${index}`}
                                                          value="active"
                                                          checked={section.status === 'active'}
                                                          onChange={(e) => handleChange(e, index)}
                                                        />
                                                        <label className="form-check-label me-2">
                                                        Inactive
                                                        </label>
                                                        <input
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`status_${index}`}
                                                          value="inactive"
                                                          checked={section.status === 'inactive'}
                                                          onChange={(e) => handleChange(e, index)}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row" style={{ marginTop: -80 }}>
                                                  <div className="col-lg-12">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Description
                                                      </label>
                                                      <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Description"
                                                        name="description"
                                                        value={section.description}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                            {section.facebook_ad_provider === 'facebook_data' && (
                                              <>
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="card-header" style={{ backgroundColor: '#ccebff' }}>
                                                      <h3 className="card-title align-items-start flex-column">
                                                        <span className="card-label fw-bolder text-dark">
                                                          Section #{section.id}
                                                        </span>
                                                      </h3>
                                                      <div className="card-toolbar">
                                                        {section.showRemoveButton && (
                                                        <button className="btn btn-icon btn-bg-danger btn-active-color-light btn-sm me-3" onClick={() => removeSection(section.id)}><Icon path={mdiMinus} size={1} /></button>
                                                        )}
                                                        {index === sections.length - 1 && (
                                                        <button className="btn btn-icon btn-bg-primary btn-active-color-primary btn-sm" onClick={addSection}>
                                                          <Icon path={mdiPlus} size={1} />
                                                        </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">Facebook Ad</label>
                                                      <select
                                                        className="form-select"
                                                        name="facebook_ad_provider"
                                                        value={formData.facebook_ad_provider || ''}
                                                        onChange={handleChange}
                                                      >
                                                        <option value="">Select Ad Provider</option>
                                                        <option value="admob">Admob</option>
                                                        <option value="app_lovin">App Lovin</option>
                                                        <option value="adsterra">Ad Sterra</option>
                                                        <option value="facebook_data">Facebook</option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row" style={{ marginTop: -80 }}>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Fb App Id
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Fb App Id"
                                                        name="fb_app_id"
                                                        value={section.fb_app_id || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        App Open Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`app_open_key_checkbox${section.id}`}
                                                          checked={section.app_open_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'app_open_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="App Open Key"
                                                        name="app_open_key"
                                                        value={section.app_open_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Banner Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`banner_key_checkbox${section.id}`}
                                                          checked={section.banner_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'banner_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Banner Key"
                                                        name="banner_key"
                                                        value={section.banner_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Interstitial Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`interstitial_key_checkbox${section.id}`}
                                                          checked={section.interstitial_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'interstitial_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Interstitial Key"
                                                        name="interstitial_key"
                                                        value={section.interstitial_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Rectangle Key
                                                        <input 
                                                          className="form-check-input h-20px w-20px me-25" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`rectangle_key_checkbox${section.id}`}
                                                          checked={section.rectangle_key_checkbox}
                                                          onChange={(e) => handleCheckboxChange(index, 'rectangle_key_checkbox')}
                                                        />
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Rectangle Key"
                                                        name="rectangle_key"
                                                        value={section.rectangle_key || ''}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                          Status
                                                      </label>
                                                      <div className="form-check form-check-custom form-check-solid">
                                                        <label className="form-check-label me-2">
                                                        Active
                                                        </label>
                                                        <input
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`status_${index}`}
                                                          value="active"
                                                          checked={section.status === 'active'}
                                                          onChange={(e) => handleChange(e, index)}
                                                        />
                                                        <label className="form-check-label me-2">
                                                        Inactive
                                                        </label>
                                                        <input
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`status_${index}`}
                                                          value="inactive"
                                                          checked={section.status === 'inactive'}
                                                          onChange={(e) => handleChange(e, index)}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row" style={{ marginTop: -80 }}>
                                                  <div className="col-lg-12">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">
                                                        Description
                                                      </label>
                                                      <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Description"
                                                        name="description"
                                                        value={section.description}
                                                        onChange={(e) => handleChange(e, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}                                            
                                          </div>
                                          )
                                        ))}

                                        {showAddSectionDropdown && (
                                          <>
                                            {addSectionData.map((sectionNew, index) => (
                                              <>
                                              <div key={sectionNew.id}>
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="card-header" style={{ backgroundColor: '#ccebff' }}>
                                                      <h3 className="card-title align-items-start flex-column">
                                                        <span className="card-label fw-bolder text-dark">
                                                          Section #{sectionNew.id}
                                                        </span>
                                                      </h3>
                                                      <div className="card-toolbar">
                                                        {sectionNew.showRemoveButton && (
                                                        <button className="btn btn-icon btn-bg-danger btn-active-color-light btn-sm me-3" onClick={() => removeSectionNew(sectionNew.id)}><Icon path={mdiMinus} size={1} /></button>
                                                        )}
                                                        {index === addSectionData.length - 1 && (
                                                        <button className="btn btn-icon btn-bg-primary btn-active-color-primary btn-sm" onClick={addSection}>
                                                          <Icon path={mdiPlus} size={1} />
                                                        </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-lg-12">
                                                    <div className="mb-10 p-10">
                                                      <label className="form-label">Ad Provider</label>
                                                      <select
                                                        className="form-select"
                                                        name="app_open_ad_provider"
                                                        value={addSectionData.app_open_ad_provider}
                                                        onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'app_open_ad_provider')}
                                                      >
                                                        <option value="">Select Ad Provider</option>
                                                        <option value="admob">Admob</option>
                                                        <option value="app_lovin">App Lovin</option>
                                                        <option value="adsterra">Ad Sterra</option>
                                                        <option value="facebook_data">Facebook</option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                                {sectionNew.app_open_ad_provider === 'admob' && (
                                                  <div className="row" style={{ marginTop: -80 }}>
                                                    <div className="col-lg-4">
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Admob App Id
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Admob App Id"
                                                          name="admob_app_id"
                                                          value={sectionNew.admob_app_id || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'admob_app_id')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          App Open Key
                                                          <input 
                                                          className="form-check-input h-20px w-20px me-10" 
                                                          style={{ marginLeft: 10 }}
                                                          type="checkbox"
                                                          id={`app_open_key_checkbox${sectionNew.id}`}
                                                          checked={sectionNew.app_open_key_checkbox}
                                                          onChange={() => handleCheckboxChangeNew(sectionNew.id, 'app_open_key_checkbox')}
                                                        />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="App Open Key"
                                                          name="app_open_key"
                                                          value={sectionNew.app_open_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'app_open_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Banner Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`banner_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.banner_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'banner_key_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Banner Key"
                                                          name="banner_key"
                                                          value={sectionNew.banner_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'banner_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Interstitial Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`interstitial_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.interstitial_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'interstitial_key_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Interstitial Key"
                                                          name="interstitial_key"
                                                          value={sectionNew.interstitial_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'interstitial_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Reward Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`reward_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.reward_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'reward_key_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Reward Key"
                                                          name="reward_key"
                                                          value={sectionNew.reward_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'reward_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Native Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`native_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.native_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'native_key_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Native Key"
                                                          name="native_key"
                                                          value={sectionNew.native_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'native_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Rectangle Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`rectangle_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.rectangle_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'rectangle_key_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Rectangle Key"
                                                          name="rectangle_key"
                                                          value={sectionNew.rectangle_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'rectangle_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                            Status
                                                        </label>
                                                        <div className="form-check form-check-custom form-check-solid">
                                                          <label className="form-check-label me-2">
                                                          Active
                                                          </label>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`status_${sectionNew.id}`}
                                                            value="active"
                                                            checked={sectionNew.status === 'active'}
                                                            onChange={(e) => handleSectionInputChangeNew(e,sectionNew.id, 'status')}
                                                          />
                                                          <label className="form-check-label me-2">
                                                          Inactive
                                                          </label>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`status_${sectionNew.id}`}
                                                            value="inactive"
                                                            checked={sectionNew.status === 'inactive'}
                                                            onChange={(e) => handleSectionInputChangeNew(e,sectionNew.id, 'status')}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-12" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Description
                                                        </label>
                                                        <textarea
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Description"
                                                          name="description"
                                                          value={sectionNew.description}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'description')}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                                {sectionNew.app_open_ad_provider === 'app_lovin' && (
                                                  <div className="row" style={{ marginTop: -80 }}>
                                                    <div className="col-lg-4">
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">Sdk Key</label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Sdk Key"
                                                          name="sdk_key"
                                                          value={sectionNew.sdk_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'sdk_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Report Key
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Report Key"
                                                          name="report_key"
                                                          value={sectionNew.report_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'report_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Event Key
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Event Key"
                                                          name="event_key"
                                                          value={sectionNew.event_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'event_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Ad Review Key
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Ad Review Key"
                                                          name="ad_review_key"
                                                          value={sectionNew.ad_review_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'ad_review_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          App Open Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`app_open_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.app_open_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'app_open_key_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="App Open Key"
                                                          name="app_open_key"
                                                          value={sectionNew.app_open_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'app_open_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Banner Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`banner_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.banner_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'banner_key_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Banner Key"
                                                          name="banner_key"
                                                          value={sectionNew.banner_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'banner_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Interstitial Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`interstitial_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.interstitial_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'interstitial_key_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Interstitial Key"
                                                          name="interstitial_key"
                                                          value={sectionNew.interstitial_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'interstitial_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Rectangle Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`rectangle_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.rectangle_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'rectangle_key_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Rectangle Key"
                                                          name="rectangle_key"
                                                          value={sectionNew.rectangle_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'rectangle_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                            Status
                                                        </label>
                                                        <div className="form-check form-check-custom form-check-solid">
                                                          <label className="form-check-label me-2">
                                                          Active
                                                          </label>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`status_${sectionNew.id}`} 
                                                            value="active"
                                                            checked={sectionNew.status === 'active'}
                                                            onChange={(e) => handleSectionInputChangeNew(e,sectionNew.id, 'status')}
                                                          />
                                                          <label className="form-check-label me-2">
                                                          Inactive
                                                          </label>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`status_${sectionNew.id}`}
                                                            value="inactive"
                                                            checked={sectionNew.status === 'inactive'}
                                                            onChange={(e) => handleSectionInputChangeNew(e,sectionNew.id, 'status')}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-12" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Description
                                                        </label>
                                                        <textarea
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Description"
                                                          name="description"
                                                          value={sectionNew.description}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'description')}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                                {sectionNew.app_open_ad_provider === 'adsterra' && (
                                                  <div className="row" style={{ marginTop: -80 }}>
                                                    <div className="col-lg-4">
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Ad URL
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`ad_url_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.ad_url_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'ad_url_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Ad URL"
                                                          name="ad_url"
                                                          value={sectionNew.ad_url || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'ad_url')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Rectangle Key
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Rectangle Key"
                                                          name="rectangle_key"
                                                          value={sectionNew.rectangle_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'rectangle_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                            Status
                                                        </label>
                                                        <div className="form-check form-check-custom form-check-solid">
                                                          <label className="form-check-label me-2">
                                                          Active
                                                          </label>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`status_${sectionNew.id}`} 
                                                            value="active"
                                                            checked={sectionNew.status === 'active'}
                                                            onChange={(e) => handleSectionInputChangeNew(e,sectionNew.id, 'status')}
                                                          />
                                                          <label className="form-check-label me-2">
                                                          Inactive
                                                          </label>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`status_${sectionNew.id}`}
                                                            value="inactive"
                                                            checked={sectionNew.status === 'inactive'}
                                                            onChange={(e) => handleSectionInputChangeNew(e,sectionNew.id, 'status')}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-12" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Description
                                                        </label>
                                                        <textarea
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Description"
                                                          name="description"
                                                          value={sectionNew.description}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'description')}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                                {sectionNew.app_open_ad_provider === 'facebook_data' && (
                                                  <div className="row" style={{ marginTop: -80 }}>
                                                    <div className="col-lg-4">
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">Fb App Id</label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Fb App Id"
                                                          name="fb_app_id"
                                                          value={sectionNew.fb_app_id || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'fb_app_id')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          App Open Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`app_open_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.app_open_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'app_open_key_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="App Open Key"
                                                          name="app_open_key"
                                                          value={sectionNew.app_open_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'app_open_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Banner Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`banner_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.banner_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'banner_key_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Banner Key"
                                                          name="banner_key"
                                                          value={sectionNew.banner_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'banner_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Interstitial Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`interstitial_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.interstitial_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'interstitial_key_checkbox')}
                                                          />
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Interstitial Key"
                                                          name="interstitial_key"
                                                          value={sectionNew.interstitial_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'interstitial_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Rectangle Key
                                                          <input 
                                                            className="form-check-input h-20px w-20px me-10" 
                                                            style={{ marginLeft: 10 }}
                                                            type="checkbox"
                                                            id={`rectangle_key_checkbox${sectionNew.id}`}
                                                            checked={sectionNew.rectangle_key_checkbox}
                                                            onChange={() => handleCheckboxChangeNew(sectionNew.id, 'rectangle_key_checkbox')}
                                                          />
                                                          </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Rectangle Key"
                                                          name="rectangle_key"
                                                          value={sectionNew.rectangle_key || ''}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'rectangle_key')}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                            Status
                                                        </label>
                                                        <div className="form-check form-check-custom form-check-solid">
                                                          <label className="form-check-label me-2">
                                                          Active
                                                          </label>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`status_${sectionNew.id}`} 
                                                            value="active"
                                                            checked={sectionNew.status === 'active'}
                                                            onChange={(e) => handleSectionInputChangeNew(e,sectionNew.id, 'status')}
                                                          />
                                                          <label className="form-check-label me-2">
                                                          Inactive
                                                          </label>
                                                          <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`status_${sectionNew.id}`}
                                                            value="inactive"
                                                            checked={sectionNew.status === 'inactive'}
                                                            onChange={(e) => handleSectionInputChangeNew(e,sectionNew.id, 'status')}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-12" style={{ marginTop: -80 }}>
                                                      <div className="mb-10 p-10">
                                                        <label className="form-label">
                                                          Description
                                                        </label>
                                                        <textarea
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Description"
                                                          name="description"
                                                          value={sectionNew.description}
                                                          onChange={(e) => handleSectionInputChangeNew(e, sectionNew.id, 'description')}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                              </>
                                            ))}
                                          </>
                                        )}
                                      </div>
                                  </div>
                                )}

                                {!isSectionAdded && sections.length === 0 &&  (
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="card-header" style={{ backgroundColor: '#ccebff' }}>
                                        <h3 className="card-title align-items-start flex-column">
                                          <span className="card-label fw-bolder text-dark">
                                            Section #
                                          </span>
                                        </h3>
                                        <div className="card-toolbar">
                                          <button className="btn btn-icon btn-bg-primary btn-active-color-primary btn-sm" onClick={addSection}>
                                            <Icon path={mdiPlus} size={1} />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                
                                <div className="card-footer d-flex justify-content-end py-6 px-0">
                                  <button
                                    type="submit"
                                    className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm me-2"
                                  >
                                    Update
                                  </button>
                                  <Link to="/application"
                                    type="reset"
                                    className="btn btn-outline btn-outline-danger btn-active-light-danger btn-sm me-2"
                                  >
                                    Cancel
                                  </Link>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <span className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={13}
                y={6}
                width={13}
                height={2}
                rx={1}
                transform="rotate(90 13 6)"
                fill="black"
              />
              <path
                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};

export default EditApplication;
