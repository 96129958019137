import React, { useState, useEffect } from "react";
//import axios from "axios";
//import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiEyeOutline, mdiEyeOffOutline} from "@mdi/js";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const disableBackButton = () => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  };

  useEffect(() => {
    const isLoggedIn = true;
    if (isLoggedIn) {
      navigate("/", { replace: true });
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {

      const staticEmail = "contact@loopbots.com";
      const staticPassword = "Loopbots@1";
      if (email === staticEmail && password === staticPassword) {
        console.log("Login successfull");
        disableBackButton();
        navigate("/application");
      } else {
        setErrors({ login: "Invalid email or password" });
      }

      // try {
      //   //const response = await axios.post("http://localhost:8000/userLogin", {
      //   const response = await axios.post("https://adkeys.loopbots.in/userLogin", {
      //     email,
      //     password,
      //   });

      //   const { data } = response;
      //   if (data.status === "success") {
      //     console.log("Login successful");
      //     disableBackButton();
      //     localStorage.setItem("sessionToken", data.token);
      //     navigate("/users");
      //   } else {
      //     console.log(data.msg);
      //     setErrors({ login: data.msg });
      //   }
      // } catch (error) {
      //   console.error("An error occurred:", error);
      //   console.log("Error code:", error.code);
      //   console.log("Error message:", error.message);
      //   setErrors({ login: "An error occurred. Please try again." });
      // } 
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email format";
    }
    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // const staticEmail = "contact@loopbots.com";
  // const staticPassword = "Loopbots@1";
  // if (email === staticEmail && password === staticPassword) {
  //   console.log("Login successfull");
  //   disableBackButton();
  //   navigate("/users");
  // } else {
  //   setErrors({ login: "Invalid email or password" });
  // }

  // try {
  //   //const response = await axios.post("http://192.168.0.57:3001/userLogin", {
  //   const response = await axios.post("https://funny.webixtechnologies.co.in/userLogin", {
  //     email,
  //     password,
  //   });
  //   const { data } = response;
    
  //   if (data.status === "success") {
  //     console.log("Login successfull");
  //     disableBackButton();
  //     navigate("/category");
  //   } else {
  //     console.log(data.msg);
  //     setErrors({ login: data.msg });
  //   }
  // } catch (error) {
  //   console.error("An error occurred:", error);
  //   setErrors({ login: "An error occurred. Please try again." });
  // }

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-4 login-signin-on d-flex flex-row-fluid"
          id="kt_login"
        >
          <div
            className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
            style={{
              height: "100vh",
              backgroundImage:
                'url("https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/bg/bg-3.jpg")',
            }}
          >
            <div className="login-form text-center p-7 position-relative overflow-hidden mb-10">
              <div className="d-flex flex-center mb-10">
                <a href="#">
                  <img
                    src="loopbots.png"
                    className="50px"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="login-signin">
                <div className="mb-20">
                  <h2>Sign In to Adkey Administrator
                  </h2>
                  <div className="text-muted font-weight-bold"></div>
                </div>
                <form className="form" id="kt_login_signin_form" onSubmit={handleSubmit}>
                  <div className="form-group mb-5">
                    <input
                      className="form-control h-auto form-control-solid py-4 px-8"
                      type="email"
                      placeholder="Email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
                  <div className="form-group mb-5">
                    <input
                      className="form-control h-auto form-control-solid py-4 px-8"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      className="position-absolute translate-middle-y end-0 me-10" style={{ marginTop: "-20px", marginLeft: "100%" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                    {showPassword ? (
                        <Icon
                        path={mdiEyeOutline}
                        size={1}
                        className="opacity-75"
                        />
                    ) : (
                        <Icon
                        path={mdiEyeOffOutline}
                        size={1}
                        className="opacity-75"
                        />
                    )}
                    </div>
                  </div>
                  {errors.password && <span style={{ color: "red" }}>{errors.password}</span>}
                  <div className="form-group mb-5">
                    {errors.login && (
                      <span style={{ color: "red" }}>{errors.login}</span>
                    )}
                  </div>
                  <div className="form-group mb-5">
                    <button
                      type="submit"
                      id="kt_login_signin_submit"
                      className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
