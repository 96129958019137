import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiListBoxOutline } from "@mdi/js"; 
import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from 'multiselect-react-dropdown';

const EditUsers = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [application_ids, setApplicationIds] = useState([]);
  const [options, setOptions] = useState([]);
  const [appsign, setAppSign] = useState('');
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    appsign: '',
  });
  const [isUsernameUnique, setIsUsernameUnique] = useState(true);
  const [isEmailUnique, setIsEmailUnique] = useState(true);

  useEffect(() => {
    //fetch(`http://localhost:8000/getUserById/${id}`)
    fetch(`https://adkeys.loopbots.in/getUserById/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setFormData(data.data);
        setApplicationIds(data.data.application_ids);
        setAppSign(data.data.appsign);
        setLoading(false);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [id]);

  useEffect(() => {
    //fetch('http://localhost:8000/getApplicationByIds')
    fetch('https://adkeys.loopbots.in/getApplicationByIds')
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success' && data.application) {
          const applicationOptions = data.application.map((application) => ({
            label: application.name,
            value: application.id,
          }));
          setOptions(applicationOptions);
        } else {
          console.error('Failed to fetch application name:', data.msg);
        }
      })
      .catch((error) => {
        console.error('Error fetching application name:', error);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === 'appsign') {
      setAppSign(value);
    }
  };

  useEffect(() => {
    if (formData.username) {
      //fetch(`http://localhost:8000/checkUsernameAvailability/${id}/${formData.username}`)
      fetch(`https://adkeys.loopbots.in/checkUsernameAvailability/${id}/${formData.username}`)
        .then((response) => response.json())
        .then((data) => {
          setIsUsernameUnique(data.isAvailable);
        })
        .catch((error) => {
          console.error('Error checking username availability:', error);
        });
    }
  }, [formData.username, id]);

  useEffect(() => {
    if (formData.email) {
      fetch(`http://localhost:8000/checkEmailAvailability/${id}/${formData.email}`)
      //fetch(`https://adkeys.loopbots.in/checkEmailAvailability/${id}/${formData.email}`)
        .then((response) => response.json())
        .then((data) => {
          setIsEmailUnique(data.isAvailable);
        })
        .catch((error) => {
          console.error('Error checking email:', error);
        });
    }
  }, [formData.email, id]);


  const handleSelectChange = (selectedList) => {
    setApplicationIds(selectedList);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm() && isUsernameUnique && isEmailUnique) {
      const rowData = {
        username: formData.username,
        email: formData.email,
        appsign,
        application_ids,
      };
      //fetch(`http://localhost:8000/editUser/${id}`, {
      fetch(`https://adkeys.loopbots.in/editUser/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rowData),
      })
      .then((response) => response.json())
      .then((data) => {
        toast.success('You have successfully updated users!');
        navigate("/users");
      })
      .catch((error) => {
        toast.error('Error in update users!', error);
      });
    }
  };
  const { username, email } = formData;
  const validateForm = () => {
    const errors = {};
    if (!username) {
      errors.username = 'User Name field is required';
    }
    if (!email) {
      errors.email = 'Email field is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email field is invalid';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <Sidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
              style={{ height: "100vh" }}
            >
              <Header />
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div className="toolbar" id="kt_toolbar">
                  <div
                    id="kt_toolbar_container"
                    className="container-fluid d-flex flex-stack"
                  >
                    <div
                      data-kt-swapper="true"
                      data-kt-swapper-mode="prepend"
                      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                      className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                    >
                      <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                        Edit User
                        <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-xxl" style={{maxWidth: "100%"}}>
                    <div className="g-5 gx-xxl-8">
                      <div className="card card-xxl-stretch">
                        <div className="card-header">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-dark">
                              Edit User
                            </span>
                          </h3>
                          <div className="card-toolbar">
                            <Link to="/users" className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm"><Icon path={mdiListBoxOutline} size={1} /> Users
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="py-0">
                            <div className="rounded border p-10">
                              <form className="form" onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        User Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="User Name"
                                        name="username"
                                        value={formData.username || ''}
                                        onChange={handleChange}
                                      />
                                      {errors.username && <span style={{ color: "red" }}>{errors.username}</span>}
                                      {!isUsernameUnique && formData.username && <span style={{ color: "red" }}>This User Name is already taken.</span>}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Email
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email || ''}
                                        onChange={handleChange}
                                      />
                                      {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
                                      {!isEmailUnique && formData.email && <span style={{ color: "red" }}>This Email is already taken.</span>}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="form-label">
                                          App Sign ?
                                      </label>
                                      <div className="form-check form-check-custom form-check-solid">
                                        <label className="form-check-label me-2">
                                          All
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="appsign"
                                          value="1"
                                          checked={formData.appsign === '1'}
                                          onChange={handleChange}
                                        />
                                        <label className="form-check-label me-2">
                                          Selection
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="appsign"
                                          value="2"
                                          checked={formData.appsign === '2'}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {appsign === '2' && (
                                    <div className="col-lg-6">
                                      <div className="mb-10">
                                        <label className="form-label">Select App Id</label>
                                        {options.length > 0 && (
                                        <Multiselect
                                          options={options}
                                          selectedValues={application_ids.map((appId) => {
                                            return options.find((option) => option.value === appId);
                                          })}
                                          onSelect={(selectedList, selectedItem) =>
                                            handleSelectChange([...application_ids, selectedItem.value])
                                          }
                                          onRemove={(selectedList, removedItem) =>
                                            handleSelectChange(application_ids.filter((appId) => appId !== removedItem.value))
                                          }
                                          displayValue="label"
                                        />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="card-footer d-flex justify-content-end py-6 px-0">
                                  <button
                                    type="submit"
                                    className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm me-2"
                                  >
                                    Update
                                  </button>
                                  <Link to="/users"
                                    type="reset"
                                    className="btn btn-outline btn-outline-danger btn-active-light-danger btn-sm me-2"
                                  >
                                    Cancel
                                  </Link>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <span className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={13}
                y={6}
                width={13}
                height={2}
                rx={1}
                transform="rotate(90 13 6)"
                fill="black"
              />
              <path
                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};

export default EditUsers;
