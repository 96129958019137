import "./App.css";
import React from 'react';
import { Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import Users from "./pages/user/Users";
import AddUsers from "./pages/user/AddUsers";
import EditUsers from "./pages/user/EditUsers";
import Application from "./pages/aplication/Aplication";
import AddApplication from "./pages/aplication/AddApplication";
import EditApplication from "./pages/aplication/EditApplication";
// import Profile from "./pages/profile/Profile";
/*import Dashboard from "./pages/dashboard/Dashboard";
import ChangePassword from "./pages/profile/ChangePassword";*/

function App() {
  return (
      <Routes>
        {/* <Route path="users" element={<Users/>}/> */}
        <Route index element={<Login/>}/>
        {/* <Route index element={<Users/>}/> */}
        <Route path="users" element={<Users/>}/>
        <Route path="adduser" element={<AddUsers/>}/>
        <Route path='/edituser/:id' element={<EditUsers />} />
        <Route path="application" element={<Application/>}/>
        <Route path="add_application" element={<AddApplication/>}/>
        <Route path="/edit_application/:id" element={<EditApplication/>}/>
        {/* <Route path="profile" element={<Profile />} /> */}
        {/* <Route index element={<Login />} /> */}
        {/* <Route path="dashboard" element={<Dashboard />} />
        <Route path="change_password" element={<ChangePassword />} />  */}
      </Routes>
  );
}
export default App;
