import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from '@mdi/react';
import { mdiLogout } from '@mdi/js';

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("sessionToken");
    navigate("/", { replace: true });
  };

  return (
    <button className="btn btn-primary font-weight-bold px-1 py-1 mx-0" onClick={handleLogout}>
        <Icon path={mdiLogout} size={1} />
      Logout
    </button>
  );
};

export default Logout;